@import "../../Common/colors";
@import "../../Common/const";

.content {
  height: calc(100vh - #{$headerHeight});
  display: flex;
  justify-content: center;
  align-items: center;

  .signInBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 448px;
    background: $white;
    box-shadow: 0 0 8px $shadow;
    border-radius: 16px;
    padding: 32px 16px;
    margin: 16px;

    .info {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 256px;

      font-size: 14px;
      line-height: 140%;
      text-align: center;

      .logo {
        margin-bottom: 40px;
      }

      .slider {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .divider {
      width: 416px;
      height: 2px;
      background: $primaryLight;
      border-radius: 2px;
      margin: 40px 0;
    }

    .social {
      display: flex;
      flex-direction: column;
      align-items: center;

      font-size: 12px;
      line-height: 150%;

      .buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px;
        & > * + * { margin-left: 8px; } // gap not work in safari

        .button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          border-radius: 10px;
          border: none;
          cursor: pointer;

          .buttonIcon {
            max-width: 24px;
            max-height: 24px;
          }
        }

        .telegramButton {
          background: $telegramButton;
        }
        .googleButton {
          background: $googleButton;
        }
        .facebookButton {
          background: $facebookButton;
        }
      }
    }

    .errorMessage {
      margin: 10px 0;
    }
  }
}
