@import "../../colors";

.dialog {
  width: 380px;

  font-family: Nunito, serif;
  font-size: 14px;
  line-height: 120%;
  color: $black;

  .header {
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    margin: 0;
  }

  .text {
    font-family: Inter, serif;
  }
}
