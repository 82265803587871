@import "../../colors";

.box {
  position: fixed;
  bottom: 32px;
  left: 32px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;

  .toast {
    font-family: Inter, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    background-color: $primaryLight;
    color: $primaryDark;
    border-radius: 10px;
    border: 1px solid $primaryDark;
    padding: 7px 10px;
    width: 215px;
    word-break: break-word;

    &.primary {
      background-color: $primaryDark;
      color: $primaryLight;
    }

    &.success {
      border-color: $green;
      background-color: $greenLight;
      color: $green;
    }

    &.warning {
      border-color: $warning;
      background-color: $warningLight;
      color: $warning;
    }

    &.danger {
      border-color: $error;
      background-color: $errorLight;
      color: $error;
    }
  }
}
