@import "../../colors";

.box {
  position: fixed;
  right: 8px;
  bottom: 80px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  background: url("../../Images/BlackHoleBackground.jpeg");
  padding: 16px;
  width: 506px;
  height: 194px;
  filter: drop-shadow(0px 8px 16px $shadow);
  border-radius: 16px;
  overflow-y: hidden;
  z-index: 5;

  transition-duration: .5s;

  &.hidden {
    display: none;
  }

  &.fullScreen {
    bottom: 8px;

    width: calc(100vw - 16px - 32px);
    height: calc(100vh - 16px - 32px);

    .goalBox .goals {
      flex-wrap: wrap;
      align-items: flex-start;
    }
  }

  &.canDrag {
    box-shadow: 0 0 16px $orange;
  }

  &.canDropOver {
    box-shadow: 0 0 16px $green;
  }

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;

    .title {
      display: flex;
      align-items: center;

      .titleIcon {
        width: 32px;
        height: 32px;
        filter: drop-shadow(0px 0px 8px $shadow);
        border-radius: 32px;
      }

      .titleText {
        font-family: Nunito, serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 130%;
        color: $white;
        margin: 0 0 0 8px;
      }
    }

    .button {
      width: 32px;
      height: 32px;
    }
  }

  .goalBox {
    flex-grow: 1;

    .goals {
      display: flex;
      align-items: center;
      & > * + * { margin-left: 16px; } // gap not work in safari
    }
  }
}
