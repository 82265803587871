@import "../../colors";

.actions {
  display: flex;
  justify-content: flex-end;
  padding-top: 16px;

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 24px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
  }

  .buttonCancel {
    border: 1px solid $border;
    box-sizing: border-box;
    background: $white;
    color: $primaryDark;
  }

  .buttonConfirm {
    background: $primaryDark;
    color: $white;
    margin-left: 16px;
  }
}
