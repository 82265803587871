@import "../../colors";

.box {
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 368px;
  min-width: 368px;
  height: 400px;
  background: $white;
  box-sizing: border-box;
  border-radius: 16px;

  &.transparency {
    opacity: 0;
  }

  &.canDrag {
    box-shadow: 0 0 16px $orange;
  }

  &.canDropOver {
    box-shadow: 0 0 16px $green;
  }

  &.canNotDropOver {
    box-shadow: 0 0 16px $error;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    gap: 10px;
    font-family: Nunito, serif;
    font-weight: bold;
    font-size: 16px;
    line-height: 130%;
    margin-bottom: 12px;

    .title {
      display: flex;
      align-items: center;
      overflow: hidden;

      .spaceIcon {
        width: 40px;
        min-width: 40px;
        height: 40px;
      }

      .spaceName {
        margin: 0 0 0 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .editIcon {
      display: none;
    }

    &:hover .editIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      min-width: 40px;
      height: 40px;
      cursor: pointer;
      background: $primaryLight;
      border-radius: 24px;
      border: none;
    }
  }

  .goals {
    flex-grow: 1;
    display: grid;
    grid-template-columns: repeat(2, 158px);
    flex-wrap: wrap;
    gap: 16px;
  }
}
