@import "../../colors";

.box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background: $white;
  border: 1px solid $border;
  box-sizing: border-box;
  cursor: pointer;

  .icon {
    max-height: 20px;
    max-width: 20px;
  }
}
