@import "../../colors";

.blackHole {
  width: 64px;
  height: 64px;
  position: fixed;
  right: 8px;
  bottom: 8px;

  background: url("../../Images/BlackHole.png");
  background-size: contain;
  filter: drop-shadow(0px 0px 8px $shadow);
  border-radius: 32px;
  border: none;
  cursor: pointer;
  transition-duration: .3s;

  &.animation :global {
    animation-name: tada;
    animation-duration: 3s;
    animation-iteration-count: infinite;
  }

  &.canDrag {
    width: 82px;
    height: 82px;
    border-radius: 41px;
    box-shadow: 0 0 16px $orange;
  }

  &.canDropOver {
    width: 82px;
    height: 82px;
    border-radius: 41px;
    box-shadow: 0 0 16px $green;
  }
}

.popup {
  display: flex;
  align-items: center;
  padding: 10px;
  text-align: center;
  z-index: 10;

  max-width: 265px;
  background: $black;
  opacity: 0.9;
  box-shadow: 0 2px 7px $shadow;
  border-radius: 8px;
  color: $white;

  font-family: Inter, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;

  &.hidden {
    display: none;
  }

  .arrow,
  .arrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: inherit;
  }

  .arrow {
    visibility: hidden;
  }

  .arrow::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }
}

.popup[data-popper-placement^='top'] > .arrow {
  bottom: -4px;
}

.popup[data-popper-placement^='bottom'] > .arrow {
  top: -4px;
}

.popup[data-popper-placement^='left'] > .arrow {
  right: -4px;
}

.popup[data-popper-placement^='right'] > .arrow {
  left: -4px;
}
