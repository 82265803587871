@import "../../colors";

.box {
  border-radius: 8px;
  padding: 10px;
  border: 1px solid $error;
  color: $error;
  background-color: rgba($error, .1);
  font-family: Inter, serif;
  font-size: 14px;
  line-height: 140%;
  text-align: justify;

  &:empty {
    display: none;
  }
}
