@import "../../Common/colors";

.content {
  padding: 0 32px;
  overflow: hidden;

  .spacesBox {
    margin: 16px 0;
    filter: drop-shadow(0px 0px 8px $shadow);
    padding: 24px;
    border-radius: 16px;
    background-image: url("../../Common/Images/DotGrid.jpeg");
    background-color: $white;
    background-origin: content-box;
    overflow-y: hidden;
  }
}
