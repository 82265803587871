@import "../../colors";

.box {
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: $white;
  box-sizing: border-box;
  border-radius: 16px;

  &.transparency {
    opacity: 0;
  }

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    font-family: Nunito, serif;
    font-weight: bold;
    font-size: 16px;
    line-height: 130%;
    margin-bottom: 12px;

    .title {
      display: flex;
      align-items: center;
      overflow: hidden;

      .spaceIcon {
        width: 40px;
        min-width: 40px;
        height: 40px;
      }

      .spaceName {
        margin: 0 0 0 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
