@import "../../colors";

.box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 220px;
  height: 252px;

  .medal {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    margin: 0;
    padding: 11px;

    font-family: Nunito, serif;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    color: $medalColor;

    background: url("./medalBackground.jpeg");
    border: 2px solid $topAchieveBorder;
    box-sizing: border-box;
    filter: drop-shadow(0px 8px 16px $shadow);
    border-radius: 24px;
  }

  .achieve {
    width: 220px;
    height: 200px;
  }

  .empty {
    width: 220px;
    height: 200px;
    background: $white;
    opacity: 0.5;
    border: 3px dashed $topAchieveBorder;
    box-sizing: border-box;
    border-radius: 16px;
  }
}
