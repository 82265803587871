@import "../../Common/colors";

.box {
  padding: 24px;
  margin: 0;
  width: calc(100vw - 2 * 24px);
  height: calc(100vh - 2 * 24px);
  background: rgba($black, 0.7);
}

.content {
  width: calc(100vw - 4 * 24px);
  height: calc(100vh - 4 * 24px);
  border-radius: 16px;
  background: radial-gradient(50% 50% at 50% 50%, $white 0%, $achieveBackground 100%);
  padding: 24px;
  overflow: auto;

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    .title {
      display: flex;
      align-items: center;

      .titleIcon {
        width: 32px;
        height: 32px;
      }

      .titleText {
        font-family: Nunito, serif;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 125%;
        color: $black;
        margin: 0 0 0 8px;
      }
    }

    .closeButton {
      width: 32px;
      height: 32px;
    }
  }

  .buttonBox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 16px 0;

    .buttonGroup {
      display: flex;
      background: $primaryLight;
      border-radius: 10px;
      padding: 3px;

      .radioButton {
        padding: 10px 16px;
        background: $primaryLight;
        color: $primaryDark;
        border: 0;
        margin: 0;
        cursor: pointer;

        font-family: Nunito, serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 120%;

        &:hover {
          box-shadow: 0 0 8px $shadow;
        }

        &.selected {
          background: $white;
          border-radius: 8px;
        }
      }
    }
  }

  .topAchievementsBox {
    display: grid;
    grid-template-columns: repeat(auto-fit, 220px);
    justify-content: center;
    gap: 24px;
    flex-wrap: wrap;
  }

  .achievements {
    display: grid;
    justify-content: flex-start;
    grid-template-columns: repeat(auto-fill, 180px);
    gap: 16px;
    min-height: 170px;
  }

  .spaceAchieves {
    display: flex;
    flex-direction: column;
    & > * + * { margin-top: 16px; } // gap not work in safari

    .spaceBox {
      cursor: move;
    }
  }
}
