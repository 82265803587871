@import "../../colors";

.popup {
  display: flex;
  align-items: center;
  padding: 10px;
  text-align: center;
  z-index: 10;

  max-width: 265px;
  background: $black;
  opacity: 0.9;
  box-shadow: 0 2px 7px $shadow;
  border-radius: 8px;
  color: $white;

  font-family: Inter, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;

  &.hidden {
    display: none;
  }

  .arrow,
  .arrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: inherit;
  }

  .arrow {
    visibility: hidden;
  }

  .arrow::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }
}

.popup[data-popper-placement^='top'] > .arrow {
  bottom: -4px;
}

.popup[data-popper-placement^='bottom'] > .arrow {
  top: -4px;
}

.popup[data-popper-placement^='left'] > .arrow {
  right: 0;
}

.popup[data-popper-placement^='right'] > .arrow {
  left: 0;
}
