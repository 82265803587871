@import "../../colors";

.dialog {
  width: 456px;

  .spaceForm {
    display: flex;
    flex-direction: column;
    & > * + * { margin-top: 16px; } // gap not work in safari

    font-family: Nunito, serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    color: $black;

    .header {
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      margin: 0;
    }

    .spaceNameLabel {
      display: flex;
      flex-direction: column;
    }

    .spaceNameInput {
      margin-top: 4px;
      background: $white;
      border: 1px solid $border;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 10px 12px;
      height: 41px;
    }

    .icons {
      display: grid;
      grid-template-columns: repeat(8, 50px);
      gap: 8px;
      margin-top: 8px;

      .icon {
        width: 50px;
        height: 50px;
        border-radius: 10px;
        cursor: pointer;
      }
    }

    .colors {
      display: flex;
      & > * + * { margin-left: 8px; } // gap not work in safari
      margin-top: 8px;

      .colorBox {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px;
        background: $white;
        border: 1px solid $border;
        box-sizing: border-box;
        border-radius: 10px;
        cursor: pointer;

        .color {
          width: 24px;
          height: 24px;
          border-radius: 16px;
        }
      }
    }

    .errorMessage {
      display: none;
    }

    .error {
      &.spaceNameLabel {
        color: $error;
      }

      .spaceNameInput {
        border: 1px solid $error;
      }

      .errorMessage {
        display: block;
        color: $error;
        margin-top: 4px;
      }
    }
  }
}
