@import "../../colors";

.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 158px;
  height: 148px;
  background: $white;
  border: 2px solid $primaryLight;
  box-sizing: border-box;
  border-radius: 16px;
  cursor: pointer;

  &.disabled {
    cursor: auto;

    .icon {
      opacity: 0.5;
    }

    .label {
      opacity: 0.5;
    }
  }

  &:hover:not(.disabled) {
    box-shadow: 0 8px 16px $shadow;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background: $primaryLight;
    border-radius: 24px;
    margin-bottom: 8px;
  }

  .label {
    font-family: Nunito, serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    color: $primaryDark;
  }
}
