@import "../../colors";

.dialog {
  width: 456px;

  .goalForm {
    display: flex;
    flex-direction: column;
    & > * + * { margin-top: 16px; } // gap not work in safari

    font-family: Nunito, serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    color: $black;

    .header {
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      margin: 0;
    }

    .goalLabel {
      display: flex;
      flex-direction: column;
    }

    .goalInput {
      margin-top: 4px;
      background: $white;
      border: 1px solid $border;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 10px 12px;
      height: 41px;
    }

    .errorMessage {
      display: none;
    }

    .error {
      &.goalLabel {
        color: $error;
      }

      .goalInput, .goalTextarea {
        border: 1px solid $error;
      }

      .errorMessage {
        display: block;
        color: $error;
        margin-top: 4px;
      }
    }
  }
}
