@import "../../colors";

.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;

  position: relative;
  width: 158px;
  min-width: 158px;
  height: 148px;
  background: $white;
  border: 2px solid $placeholder;
  box-sizing: border-box;
  border-radius: 16px;
  & > * + * { margin-top: 6px; } // gap not work in safari

  .icon {
    width: 40px;
    height: 40px;
    opacity: 0.7;
  }

  .placeholder_1 {
    width: 120px;
    height: 8px;
    background: $placeholder;
    opacity: 0.7;
    border-radius: 2px;
  }

  .placeholder_2 {
    width: 110px;
    height: 8px;
    background: $placeholder;
    opacity: 0.7;
    border-radius: 2px;
  }

  .placeholder_3 {
    width: 80px;
    height: 8px;
    background: $placeholder;
    opacity: 0.3;
    border-radius: 2px;
  }

  .checkbox {
    position: absolute;
    left: 12px;
    top: 12px;
    padding: 0;
    width: 20px;
    height: 20px;
    background: $white;
    border: 1px solid $border;
    box-sizing: border-box;
    border-radius: 16px;
  }

  &.achievement {
    width: 180px;
    height: 170px;

    .icon {
      width: 60px;
      height: 60px;
    }

    .checkbox {
      display: none;
    }
  }
}
