@import "../../colors";

.box {
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 368px;
  min-width: 368px;
  height: 400px;
  background: $white;
  border: 2px solid $placeholder;
  box-sizing: border-box;
  border-radius: 16px;

  .header {
    display: flex;
    align-items: center;
    height: 40px;
    margin-bottom: 12px;

    .spaceIcon {
      width: 40px;
      height: 40px;
      opacity: 0.7;
    }

    .spaceName {
      margin: 0 0 0 10px;
      width: 140px;
      height: 18px;
      background: $placeholder;
      opacity: 0.5;
      border-radius: 4px;
    }
  }

  .goals {
    flex-grow: 1;
    display: grid;
    grid-template-columns: repeat(2, 158px);
    flex-wrap: wrap;
    gap: 16px;
  }
}
