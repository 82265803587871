@import "../../colors";

.spacesBox {
  margin: 16px 0;
  filter: drop-shadow(0px 0px 8px $shadow);
  padding: 24px;
  border-radius: 16px;
  background-image: url("../../../Common/Images/DotGrid.jpeg");
  background-color: $white;
  background-origin: content-box;
  overflow-y: hidden;

  .spaces {
    display: flex;
    & > * + * { margin-left: 16px; } // gap not work in safari
    min-height: 400px;

    .spaceBox {
      cursor: move;
    }

    .createBox {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
