@import "../../colors";

.link {
  font-family: Inter, serif;
  font-size: 14px;
  line-height: 140%;
  text-align: center;

  color: $primaryDark;
  text-decoration: none;
  border-bottom: 1px solid $borderLink;
  padding-bottom: 1px;
}
