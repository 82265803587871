@import "../../colors";

.achievesBox {
  border-radius: 16px;
  background-image: url("../../Images/DotGrid.jpeg");
  background-color: $white;
  background-origin: content-box;
  overflow-y: hidden;

  display: flex;
  flex-direction: column;
  min-height: 258px;
  padding: 24px;

  &.canDrag {
    box-shadow: 0 0 16px $orange;
  }

  &.canDropOver {
    box-shadow: 0 0 16px $green;
  }

  .header {
    display: flex;
    justify-content: space-between;

    .title {
      display: flex;
      align-items: center;

      .titleIcon {
        width: 24px;
        height: 24px;
      }

      .titleText {
        font-family: Nunito, serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 130%;
        color: $black;
        margin: 0 0 0 8px;
      }
    }

    .button {
      width: 32px;
      height: 32px;
    }
  }

  .achievementBox {
    flex-grow: 1;
    display: flex;
    align-items: center;

    .achievements {
      display: flex;
      & > * + * { margin-left: 16px; } // gap not work in safari
    }
  }
}
