@import "../../Common/colors";

.box {
  max-width: 768px;
  margin: 16px auto;
  padding: 32px;
  border-radius: 16px;

  background-color: $white;
  color: $black;
  font-family: Inter, serif;
  font-size: 14px;
  line-height: 140%;

  h2 {
    font-family: Nunito, serif;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
  }

  h3 {
    font-family: Nunito, serif;
    font-weight: bold;
    font-size: 16px;
    line-height: 130%;
  }
}
