.title {
  display: flex;
  align-items: center;

  .titleIcon {
    width: 40px;
    height: 40px;
    border: none;
  }

  .titleText {
    font-family: Nunito, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 130%;
    padding: 0;
    margin: 0 0 0 10px;
  }
}
