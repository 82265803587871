@import "../../colors";

.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;

  position: relative;
  width: 158px;
  min-width: 158px;
  height: 148px;
  background: $white;
  border: 2px solid $primaryLight;
  box-sizing: border-box;
  border-radius: 16px;
  text-align: center;
  transform: translate(0, 0);

  &.transparency {
    opacity: 0;
  }

  &.editable {
    cursor: pointer;

    &:hover {
      box-shadow: 0 8px 16px $shadow;
    }

    .checkbox:hover {
      box-shadow: 0 8px 16px $shadow;
    }
  }

  .icon {
    width: 40px;
    height: 40px;
    border: none;
  }

  .name {
    font-family: Nunito, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    word-break: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: $black;
    margin: 6px 0;
  }

  .deadline {
    font-family: Inter, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    color: $grey;

    &.overdue {
      color: $error;
    }
  }

  .checkbox {
    position: absolute;
    left: 12px;
    top: 12px;
    padding: 0;
    width: 20px;
    height: 20px;
    background: $white;
    border: 1px solid $border;
    box-sizing: border-box;
    border-radius: 16px;

    &.hidden {
      display: none;
    }
  }
}
