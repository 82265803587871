@import "../../colors";

.title {
  display: flex;
  align-items: center;

  .titleIcon {
    width: 40px;
    height: 40px;
    border: none;
  }

  .titleText {
    font-family: Nunito, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    padding: 0;
    margin: 0 30px 0 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
