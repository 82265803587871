@import "../../colors";

.icon {
  background: #FFFFFF;
  border: 1px solid $border;
  box-sizing: border-box;
  border-radius: 8px;

  &.select {
    outline: 2px solid $primaryDark;
  }
}
