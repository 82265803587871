@import "../../colors";

.dialog {
  width: 456px;

  .goalForm {
    display: flex;
    flex-direction: column;
    & > * + * { margin-top: 16px; } // gap not work in safari

    font-family: Nunito, serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    color: $black;

    .header {
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      margin: 0;
    }

    .goalLabel {
      display: flex;
      flex-direction: column;
    }

    .goalInput {
      margin-top: 4px;
      background: $white;
      border: 1px solid $border;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 10px 12px;
      height: 41px;
    }

    .goalTextarea {
      margin-top: 4px;
      background: $white;
      border: 1px solid $border;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 10px 12px;
      resize: none;
    }

    .icons {
      display: grid;
      grid-template-columns: repeat(8, 50px);
      gap: 8px;
      margin-top: 8px;

      .focusableIcon {
        width: 50px;
        height: 50px;
        border: none;
        margin: 0;
        padding: 0;
        background: none;
        border-radius: 10px;
      }

      .icon {
        width: 50px;
        height: 50px;
        padding: 8px;
        border-radius: 10px;
        cursor: pointer;
      }
    }

    .errorMessage {
      display: none;
    }

    .error {
      &.goalLabel {
        color: $error;
      }

      .goalInput, .goalTextarea {
        border: 1px solid $error;
      }

      .errorMessage {
        display: block;
        color: $error;
        margin-top: 4px;
      }
    }
  }
}

.popup {
  overflow: hidden;
  z-index: 10;

  background: $white;
  box-shadow: 0 2px 7px $shadow;
  border-radius: 8px;

  .scrollable {
    max-height: 223px;
    overflow-y: auto;
    overflow-x: hidden;

    .filterBox {
      position: sticky;
      top: 0;
      padding: 5px;
      margin: 0 8px;
      display: flex;
      justify-content: center;
      background-color: $primaryLight;
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;

      .filterButton {
        cursor: pointer;
        padding: 5px 16px;

        &.selected {
          background-color: $white;
          border-radius: 8px;
        }
      }
    }

    .icons {
      padding: 0 10px 10px;
    }
  }

  &.hidden {
    display: none;
  }
}

.popup[data-popper-placement^='top'] > .arrow {
  bottom: -4px;
}

.popup[data-popper-placement^='bottom'] > .arrow {
  top: -4px;
}

.popup[data-popper-placement^='left'] > .arrow {
  right: -4px;
}

.popup[data-popper-placement^='right'] > .arrow {
  left: -4px;
}
